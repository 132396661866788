import React from "react"
import styled from "@emotion/styled"

const LastViewButton = ({ zoom, latitude, longitude, onClick }) => {
  const LastViewButton = styled.button`
    -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0.05, #f9f9f9),
      color-stop(1, #e9e9e9)
    );
    background: -moz-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
    background: -webkit-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
    background: -o-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
    background: -ms-linear-gradient(top, #f9f9f9 5%, #e9e9e9 100%);
    background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9',GradientType=0);
    background-color: #f9f9f9;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    cursor: pointer;
    color: #666666;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #ffffff;

    &:hover {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0.05, #e9e9e9),
        color-stop(1, #f9f9f9)
      );
      background: -moz-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
      background: -webkit-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
      background: -o-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
      background: -ms-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
      background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9e9e9', endColorstr='#f9f9f9',GradientType=0);
      background-color: #e9e9e9;
    }
  `

  if (zoom > 3 && zoom < 7) {
    return (
      <LastViewButton
        onClick={() =>
          onClick({
            width: window.innerWidth,
            height: window.innerHeight,
            latitude: 0,
            longitude: 0,
            zoom: 1,
            pitch: 0
          })
        }>
        World View
      </LastViewButton>
    )
  }
  if (zoom > 7) {
    return (
      <LastViewButton
        onClick={() =>
          onClick({
            width: window.innerWidth,
            height: window.innerHeight,
            latitude: latitude,
            longitude: longitude,
            zoom: 4,
            pitch: 0
          })
        }>
        City View
      </LastViewButton>
    )
  } else {
    return null
  }
}

export default LastViewButton
